<template>
  <div class="aboutUs">
    <div class="aboutUs_banner">
      <img src="@/assets/img/AboutUs/banner-about.png" />
    </div>
    <div class="aboutUs_int">
      <div style="height: 100%;">
        <img src="@/assets/img/AboutUs/bg_introduction.png" />
      </div>
      <div class="AboutIntroduction_content">
        <div>
          <img src="@/assets/img/AboutUs/pic-company.png" />
        </div>
        <div>
          <div>
            <div class="companyName">介绍冰眸软件</div>
            <div class="companyEnglishName">
              <span>Guangzhou VLimSoft Co., Ltd.</span>
            </div>
          </div>
          <div class="companyInt">
            <!-- 广州冰眸电子有限公司成立于2021年，位于广州市科学城基地的生产力促进中心。冰眸电子是一家专注于视觉检测和视觉工业自动化的高新企业，通过CV、DL等各种技术，努力打造视觉引导工业自动化的人工智能，极大促进生产力的发展。<br />
            冰眸电子高度重视自主创新，自公司成立以来，先后研发了具有自主知识产权的三维立体测距、手掌检测、二维码快速识别、视觉高精度测量（尺寸测量、计数、缺陷检测）等相关核心技术。并成功开发了具有自主软件著作权的服务端设备监控、平台化设备监控、机械臂控制、工业设备视觉检测、设备监控等系统检测软件。为客户研制出的高精度视觉对位系统、以及高可靠性的自动化检测设备及整体解决方案，同时可供应配套的工业相机、工业镜头、视觉光源及专业图像处置软件等标准产品。还为客户提供项目评价、计划设计、设备调试、技能咨询及训练等一体化的专业服务。<br />
            广州冰眸电子有限公司正处于高速发展时期，我们除了拥有领先水平的机器视觉商品之外，还能够获得提供的全方位的服务与支持，然后进一步为客户为客户提供全方位的协助。如果是接受过技能训练的直销人员，不仅仅只能处置疑难的的实践使用问题，还能随时处理与我们商品有关的任何技能疑问。公司全体员工将秉承＂炼就火眼金睛，成就智能机器＂的使命，朝着＂2025年，成为视觉领域的知名企业＂这一伟大愿景而迈进！ -->
             <div style="padding-top:20px">冰眸助你解决食物屯积麻烦</div>
           <div>“冰眸”采用了物联网技术、云端服务器。任何环境下都可以通过APP及小程序了解冰箱食物存储情况，根据用户需求随时随地购买补充食物；系统会及时发送食物逾期信息提醒用户及时使用或处理食材</div>
          <div style="padding-top:20px">冰箱食物进出时间管理</div>
          <div>提供当前天气状况，可以进行保质提醒、食物分类以及自定义冰箱的存藏区域管理，清楚知道冰箱食物的进出时间记录，让食物保质管理成为可能</div>
          <div style="padding-top:20px">食物推荐及小区商铺购物</div>
          <div>根据需求定位查看附近食材及门店情况，清楚知道当时时令推荐使用的食材信息，根据APP上显示的食材存量及时补充食物，享受购物便捷的服务</div>
          <div style="padding-top:20px">参考食物制作、分享烹饪心得</div>
          <div>查看各种各样食物制作的方法，让冰箱食物的制作不再是一件难事。也可以把自己食物烹饪技术及方法分享给他人</div>
          <div style="padding-top:20px">冰箱管理、成员设置简单易用</div>
          <div>轻松设置冰箱分区管理，个人资料信息，消息中心、提供送货地址信息、菜谱发表及操作、记录自己的行程及影像等一应俱全</div>
          </div>
        </div>
      </div>
    </div>
    <div class="aboutUs_course">
      <div>
        <div class="newTitle">
          <div>发展历程</div>
          <div>development history</div>
          <div></div>
        </div>
        <div class="historyRecord">
          <!-- 日期侧边栏 -->
          <div class="dateRecord">
            <div
              v-for="(item, index) in historyRecord"
              :key="index"
              @click="handleCurrentChange(index, item.list)"
            >
              <div :class="selectCurrent == index ? 'dateRecord_active' : ''">
                {{ item.time }}
              </div>
              <div
                :class="
                  selectCurrent == index
                    ? 'dateRecord_dianActive'
                    : 'dateRecord_dian'
                "
              ></div>
            </div>
          </div>
          <!-- 历程内容 -->
          <div class="historyContent">
            <transition-group name="slide-fade">
              <div
                class="historyContent_item"
                v-for="item in courseContent"
                :key="item"
                v-show="!!courseContent"
              >
                {{ item }}
              </div>
            </transition-group>
          </div>
        </div>
      </div>
    </div>
    <div class="homeLink">
      <div>
        <div class="homeLink_title">
          <div>友情链接</div>
          <div>friendly link</div>
        </div>
        <div class="homeLink_link">
          <div @click="goLink('http://www.vlimsoft.com/')">
            <img src="@/assets/img/home/link-vlimsoft.png" />
          </div>
          <div
            @click="
              goLink(
                'https://shop389691363.taobao.com/?spm=a230r.7195193.1997079397.2.77371f40wd5lS2'
              )
            "
          >
            <img src="@/assets/img/home/link-tbdp.png" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 历史记录
      historyRecord: [
        {
          time: "2021",
          list: [
            // "2021年12月广州微林股份有限公司成立！",
            "2021年08月冰眸小程序完成升级，APP在各大应用市场上架",
            "2021年04月冰眸二期改版完成，项目整体规划完成",
          ],
        },
        {
          time: "2020",
          list: [
            "2020年10月冰眸项目核心算法模型完成，申请6项专利",
            "2020年04月视觉检测产品被认定为广东省高新科技产品",
          ],
        },
        {
          time: "2019",
          list: [
            "2019年12月获认证为广东省第一批国家高新科技企业",
            "2019年05月完成视觉检测项目，机械臂引导控制的研发",
          ],
        },
        {
          time: "2018",
          list: ["2018年02月获得国家专利，知识产权管理体系认证"],
        },
        {
          time: "2017",
          list: [
            // "2017年01月广州微林软件有限公司正式挂牌成立，开始涉足视觉检测行业",
          ],
        },
      ],
      // 选中当前
      selectCurrent: 0,
      // 历程内容
      courseContent: [],
    };
  },
  created() {
    this.courseContent = this.historyRecord[0].list;
  },
  methods: {
    // 跳转外网
    goLink(url) {
      window.open(url, "_blank");
    },
    // 处理当前日期选中
    handleCurrentChange(index, item) {
      this.selectCurrent = index;
      this.courseContent = [];
      setTimeout(() => {
        this.courseContent = item;
      }, 300);
    },
  },
};
</script>

<style scoped>
.aboutUs_banner {
  width: 100%;
  height: 300px;
}
.aboutUs_banner img {
  width: 100%;
  height: 100%;
  display: flex;
  object-fit: cover;
}
/* 介绍 */
.aboutUs_int {
  width: 100%;
  height: 788px;
  position: relative;
}
.aboutUs_int img {
  width: 100%;
  height: 100%;
  display: flex;
  object-fit: cover;
}
/* 内容 */
.AboutIntroduction_content {
  position: absolute;
  top: 120px;
  left: 50%;
  width: 1440px;
  display: flex;
  transform: translateX(-50%);
  justify-content: space-between;
}
.AboutIntroduction_content > div:nth-of-type(1) {
  margin-right: 75px;
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  line-height: 30px;
}
.AboutIntroduction_content img {
  width: 610px;
  height: 558px;
  display: flex;
  object-fit: cover;
}
.companyName {
  font-size: 32px;
  color: #333333;
  font-weight: 500;
  line-height: 48px;
}
.companyEnglishName {
  font-size: 20px;
  color: #333333;
  padding: 5px 10px;
  width: 50%;
  position: relative;
}
.companyInt {
  font-size: 18px;
  color: #999999;
  margin-top: 60px;
}
/* 历程 */
.aboutUs_course {
  width: 1440px;
  margin: 120px auto 160px;
}
/* 新标题 */
.newTitle {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}
.newTitle > div:nth-of-type(1) {
  font-size: 40px;
  color: #333333;
}
.newTitle > div:nth-of-type(2) {
  font-size: 20px;
  color: #333333;
  text-transform: uppercase;
}
.newTitle > div:nth-of-type(3) {
  width: 380px;
  margin-top: 14px;
  border-bottom: 1px solid #C4C4C4;
}
/* 发展历程内容 */
.historyRecord {
  width: 100%;
  height: 400px;
  display: flex;
  margin-top: 120px;
  align-items: center;
  justify-content: space-between;
}
.dateRecord {
  width: 25%;
  height: 100%;
  color: #ccc;
  display: flex;
  font-size: 30px;
  transition: all 0.5s;
  position: relative;
  align-items: flex-end;
  flex-direction: column;
  justify-content: center;
  border-right: 1px solid #ccc;
}
.dateRecord > div {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 6px 0;
  cursor: pointer;
}
.dateRecord_dian {
  right: -8px;
  width: 16px;
  height: 16px;
  margin-left: 20px;
  position: relative;
  transition: all 0.5s;
  border-radius: 100%;
  background-color: #cccccc;
}
.dateRecord_active {
  font-size: 60px;
  font-weight: 500;
  color: transparent;
  
  background: linear-gradient(to right, #64DFC9, #47B0B4);
  background-clip: text;
}
.dateRecord_dianActive {
  width: 24px;
  height: 24px;
  right: -12px;
  margin-left: 20px;
  position: relative;
  border-radius: 100%;
  transition: all 0.5s;
  background: linear-gradient(to right, #64DFC9 0%, #47B0B4 100%);
}

/* 历程内容 */
.historyContent {
  width: 65%;
  height: 100%;
}
.historyContent_item {
  font-weight: 500;
  font-size: 30px;
  color: #333333;
  line-height: 45px;
  margin-top: 60px;
}

/* 可以设置不同的进入和离开动画 */
/* 设置持续时间和动画函数 */
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for below version 2.1.8 */ {
  transform: translateY(20px);
  opacity: 0;
}
/* 友情链接 */
.homeLink {
  background: #f5f5f5;
  padding: 120px 0;
  box-sizing: border-box;
}
.homeLink > div {
  width: 1140px;
  margin: 0 auto;
}
.homeLink_title > div:nth-of-type(1) {
  font-size: 40px;
  font-weight: 500;
  color: #47b0b4;
  text-align: center;
}
.homeLink_title > div:nth-of-type(2) {
  font-size: 20px;
  font-weight: 400;
  color: #47b0b4;
  text-align: center;
  margin-top: 20px;
  text-transform: uppercase;
}
.homeLink_link {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 80px;
  height: 240px;
}
.homeLink_link > div {
  width: 240px;
  height: 100px;
  margin: 0 30px;
  box-sizing: border-box;
}
.homeLink_link img {
  display: flex;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
</style>